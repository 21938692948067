export const frenchCopy = {
    "header": {
        "projects": "Projets",
        "experience": "Expérience",
        "about": "A propos",
        "lang": "EN"
    },
    "home": {
        "heading": "J'me présente, je m'appelle ",
        "subheading": "Développeuse Front-End, j'aime construire des expériences pour le web",
        "desc": "Diplômée de la formation Développeur Web OpenClassrooms, je suis à la recherche de mon premier poste en tant que Développeuse Front-End.",
        "about1": "Passionnée de technologie",
        "about2": ", je travaille dans le monde du web depuis 10 ans.  Aujourd'hui, je sors d'une formation de Développeur Web et je cherche des projets à développer.",
        "about3": "J'aime",
        "about4": "💪 Travailler sur des projets qui me challengent",
        "about5": "👩‍🏫 Apprendre de nouvelles choses",
        "about6": "⭐️ Améliorer l'expérience utilisateur",
        "about7": "🧩 Résoudre des problèmes",
        "about8": "... ⚽️ Le foot",
        "about9": "Je n'aime pas",
        "about10": "😴 M'ennuyer",
        "about11": "👸 Les jeux politiques",
        "about12": "🤫 Le manque d'honneteté",
        "about13": "... 😱 Les films d'horreur",
        "contact": "Si vous voulez parler d'un projet, n'hésitez pas à me contacter !",
        "contactcta": "Me contacter"
    },
    "mainheader": {
        "projects": "Projets",
        "experience": "Expérience",
        "about": "À propos",
        "contact": "Contact"
    },
    "projects": {
        "kanap": "Intégration du site Kanap avec Javascript. Récupération des produits avec une API, création d'un panier via le local storage, et envoi de la commande au serveur.",
        "kasa": "Intégration du site Kasa en React. Utilisation des states React, des composants, et mise en ligne du site via Github pages.",
        "ohmyfood": "Intégration du site Ohmyfood! en HTML et avec SASS. Utilisation des animations CSS (transition et keyframes).",
        "airpeger": "Création du site Airpéger. Intégration en React, création de la partie serveur avec Node.js (création de compte, stockage des informations utilisateur, ...), et mise en ligne du site (OVH / Vercel).",
        "seemore": "Plus d'informations"
    },
    "projects_detail": {
        "kanap1": "",
        "kanap2": "",
        "kasa1": "J'ai travaillé sur le projet Kasa dans le cadre de ma formation et il s'agit de mon premier projet en React. Ce projet m'a permis de découvrir la librairie, de créer des modules courants, et de",
        "kasa2": "me baser sur les maquettes pour créer la meilleure expérience utilisateur",
        "kasa3": "possible."
    },
    "resume": {
        "experience": "Expérience",
        "training": "Formation",
        "skills": "Compétences",
        "oc2": "Knowledge Management Specialist",
        "oc2date": "oct 2022 - aujourd'hui",
        "oc2item1": "Gestion de la connaissance pour toute l'équipe Opérations",
        "oc2item2": "Personnalisation de nos thèmes Help Center (HTML/CSS)",
        "oc2item3": "Définition et suivi des indicateurs (qualité, impact, ...)",
        "oc1": "Mentor & Coach Support Manager",
        "oc1date": "juil 2019 - sep 2022",
        "oc1item1": "Mise en place des process du Support mentorat et coaching",
        "oc1item2": "Création de notre page contact custom (HTML/CSS/JS)",
        "oc1item3": "Aide à la mise en place des centres d’aide (étudiants, mentor, employeur)",
        "drivy": "Chef de Projet Service Client",
        "drivydate": "jan 2018 - juin 2019",
        "drivyitem1": "Rédaction et mise à jour du Help Center (6 pays)",
        "drivyitem2": "Remontée des retours utilisateurs via une newsletter interne",
        "drivyitem3": "Refonte et rédaction du système de macros (6 pays)",
        "schibsted": "Customer Success Manager (Londres)",
        "schibsteddate": "mars 2016 - dec 2017",
        "schibsteditem1": "Lancement de l'équipe Support client (procédure, formation)",
        "schibsteditem2": "Remontée des demandes et suggestions utilisateurs",
        "schibsteditem3": "Analyse des comportements utilisateurs sur plusieurs produits (web analytics & user testing) pour recommandations produit",
        "lbc": "Chargée de prod et process Relation Client",
        "lbcdate": "oct 2012 - fév 2016",
        "lbcitem1": "Traitement des demandes utilisateurs (Niveau 2)",
        "lbcitem2": "Envoi et analyse d'enquêtes de satisfaction pour améliorer notre NPS",
        "lbcitem3": "Rédaction de procédure pour améliorer la qualité de l’équipe (de 78% à 92%)",
        "download": "Télécharger mon CV"
    }
}